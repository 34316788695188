import React from 'react'
import PressLayout from '../../components/pressLayout'
import Share from '../../components/share'
import { Link } from 'gatsby'

export default function HelpingLocalBusinessesDeliver () {
  const shareTitle = 'Local company Trexity is helping Ottawa businesses deliver 1,000 packages a day to residents.'
  const videoLink = 'https://ottawa.ctvnews.ca/video?clipId=2346893'

  return (
    <PressLayout>
      <section className='story'>
        <div className='row align-center'>
          <div className='col'>
            <div className="inner">
              <h1>Local company Trexity is helping Ottawa businesses deliver 1,000 packages a day to residents.</h1>

              <header className="article-header">
                <div className="article-details">
                  <span className='news'>News</span>
                  <span>December 20, 2021</span>
                </div>

                <Share shareTitle={shareTitle} />
              </header>

              <div className="article-body">
                <a href={videoLink} target="_blank" rel="noreferrer"><img src="/img/press/ottawa-trexity-helping-local.jpg" alt="Copcakes and local news title" /></a>
                <div className="snippet">The following preview is from <a href={videoLink} target="_blank" rel="noreferrer">CTV Ottawa</a></div>
                <p>Alok Ahuja, CEO, explains how Trexity works to help local merchants deliver to their customers in this CTV interview.</p>
                <a href={videoLink} target="_blank" rel="noreferrer">Watch interview on CTV Ottawa</a>
              </div>

              <footer className="article-footer">
                <div className="article-next">
                  <Link to="/press/elevate-your-christmas-dinner" className='cta-link'>Related Article <i className="material-icons">keyboard_arrow_right</i></Link>
                  <p>Local Chef Michael Blackie on his “Christmas Evening At Home”.</p>
                </div>

                <Share shareTitle={shareTitle} />
              </footer>
            </div>
          </div>
        </div>
      </section>
    </PressLayout>
  )
}
